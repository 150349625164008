import moment from 'moment';

export class FiltervalidadoValueConverter {
  toView(array, propertyName, filterType) {

    // console.log("Array", array, "propertyName", propertyName, "type", filterType);

    if (!array) {
      return [];
    }

    if (filterType == 0) {
      return array;
    }

    return array.filter(v => {
      // console.log("Estado_doc", v[propertyName], "validado", v[propertyName].validado);

      let estadoDoc = v[propertyName];

      // console.log("Estado_doc", estadoDoc, "validado", estadoDoc.obligatorio, estadoDoc.ok, estadoDoc.pendiente);

      let validado = (estadoDoc.obligatorio == 0 && estadoDoc.pendiente == 0);
      // let validado = (estadoDoc.obligatorio == 0 && estadoDoc.ok > 0 && estadoDoc.pendiente == 0);
      if (filterType == 1) {
        return validado;
      } else if (filterType == 2) {
        return !validado;
      }
    });
  }
}


