import moment from 'moment';

export class FilterdateValueConverter {
  toView(array, propertyName, filterType) {

    // console.log("Array", array, "propertyName", propertyName, "type", filterType);

    if (!array) {
      return [];
    }

    if (filterType == "todas") {
      return array;
    }

    return array.filter(v => {
      if (filterType == "alta") {
        return v[propertyName] == "";
      } else if (filterType == "baja") {
        let fechaBaja = moment(v[propertyName], 'DD-MM-YYYY');
        return fechaBaja < moment();
      }
    });
  }
}


