import { HoraDelTrabajo, Lesion, MedidaCorrectora, ActividadCt, LugarCt, MensajeCt, TipoComision, TipoInforme, TipoLibroAct, TipoRegistroLibro, TipoEstadoInspeccion, SubtipoActividadPreventiva, Riesgo, FactorRiesgo, Consecuencia, NivelExposicion, ProbabilidadSuceso, CategoriaPEE, CargoPEE, TurnoPEE } from './../entities/actPrev';
import { autoinject, singleton } from 'aurelia-framework';
import { DialogService, DialogController, DialogOpenResult } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

import { HttpService } from 'utils/http-service';
import MapUtils from 'utils/maputils';
import { ImperativeJsonProperty } from 'utils/maputils';
import AuthService from 'authService';
import { AppSettings } from 'utils/app-settings';
import { GenericDlg } from 'utils/genericDlg';
// import moment from 'moment';
import moment from 'moment';
// import * as Toastr from 'toastr';

import * as _ from 'lodash';
import * as $ from 'jquery';
import 'block-ui';
import { BasicData, Empresa, EmpresaCCC } from 'entities/empresa';
import { EmpresaCdT } from 'entities/centro';
// import { resolve } from 'bluebird';
import { Trabajador } from 'entities/trabajador';
import { TrabajadorCdt } from 'entities/trabajadorCdt';
import { Maquina } from 'entities/maquinas';
import { Accidente, ActividadPreventiva, AgenteMaterial, FormaDeContacto, NaturalezaDeLaLesion, ParteDelCuerpo, TrabajadorAccidente, TrabajadorActividadPreventiva } from 'entities/actPrev';
import { AptoMedicoLaboral, AtributoCalidad, AtributoFormacion } from 'entities/documento';

let apiInstance = null;
export function GetAPI(): Api {
  return apiInstance;
}


@singleton()
@autoinject
export class Api {

  basicData: BasicData;
  empresas: Empresa[] = [];
  empresasCdt: EmpresaCdT[] = [];

  formaDeContactoList: FormaDeContacto[] = [];
  agentesMateriales: AgenteMaterial[] = [];
  partesDelCuerpo: ParteDelCuerpo[] = [];
  lesiones: Lesion[] = [];
  naturalezaDeLaLesion: NaturalezaDeLaLesion[] = [];
  horasDelTrabajoList: HoraDelTrabajo[] = [];
  actividadesCt: ActividadCt[] = [];
  lugaresCt: LugarCt[] = [];
  mensajesCt: MensajeCt[] = [];
  tiposComision: TipoComision[] = [];
  tiposInforme: TipoInforme[] = [];
  tiposLibroAct: TipoLibroAct[] = [];
  tiposRegistroLibro: TipoRegistroLibro[] = [];
  tiposEstadoInspeccion: TipoEstadoInspeccion[] = [];
  subTiposActPrev: SubtipoActividadPreventiva[] = [];
  //
  riesgos: Riesgo[] = [];
  factoresRiesgo: FactorRiesgo[] = [];
  consecuencias: Consecuencia[] = [];
  nivelesExposicion: NivelExposicion[] = [];
  probabilidadSuceso: ProbabilidadSuceso[] = [];
  categoriasPEE: CategoriaPEE[] = [];
  cargosPEE: CargoPEE[] = [];
  turnosPEE: TurnoPEE[] = [];

  aptosMedicosList: AptoMedicoLaboral[] = [];
  atributosFormacionList: AtributoFormacion[] = [];
  atributosCalidadList: AtributoCalidad[] = [];


  private alertDlg: DialogOpenResult = null;
  private uploading: boolean = false;

  constructor(public app: AppSettings, private authService: AuthService, private eventAggregator: EventAggregator,
    public httpClient: HttpService,
    public i18n: I18N,
    public dialogService: DialogService, private controller: DialogController) {

    console.log("API:constructor");

    if (!apiInstance) {
      apiInstance = this;
      // this.eventAggregator.subscribe("auth:logout", () => {
      //   console.log("Logout Event received");
      //   this.StopConnection();
      // });

      this.eventAggregator.subscribe("http:slow_request", () => {
        console.log("SLOW request detected");
        this.blockUI("Procesando petición...");
      });

      this.eventAggregator.subscribe("http:slow_request_ended", () => {
        console.log("SLOW request finished");
        this.unblockUI();
      });


      this.getOne(BasicData, "basic_data")
        .then((bd) => {
          this.basicData = bd;
          this.app.Title = bd.app_name;
          this.app.SubTitle = bd.app_sub_name;
          this.app.AppWeb = bd.app_web;
          this.app.BuildDate = bd.build_date;
          this.app.UseOpenID = bd.use_openid;
        })

    }

    return apiInstance;
  }


  getBoolean(value: any): boolean {
    return (value === true || value === 'true');
  }

  checkErrors(result: any) {
    console.log("checkErrors", result);
    let response = result.response;
    let data = result.data;

    if (!data) {
      // Handle error
      let title = this.tr("error_detectado");
      let body = "No se ha recibido ninguna respuesta";
      this.alert(title, body, true);
      return data;
    }

    if (data.error) {
      // Handle error
      let title = this.tr("error_detectado");
      let body = data.error;
      console.log("checkErrors ERROR HANDLED", result, data, title, body);
      this.alert(title, body, true);
      return data;
    }

    if (response.ok) {
      return data;
    }

    // Handle error
    let title = response.status + " - Error detectado: " + data.responseID;
    let body = data.message;
    console.log("checkErrors ERROR HANDLED", result, data, title, body);

    // if (response.status >= 500) {
    //     this.disconnectionDetected();
    // } else if (response.status >= 401) {
    //     // Don't show alert, will be redirected to login screen
    //     this.authService.logout();
    // }
    // else if (response.status >= 400) {
    //     this.alert(title, body);
    // }


    if (response.status == 401) {
      // Don't show alert, will be redirected to login screen
      this.authService.logout();
    } else if (response.status >= 400) {
      this.alert(title, body, true);
    }

    return data;
  }

  alert(title?: string, message?: string, isPlainText?: boolean) {
    if (this.alertDlg != null) {
      console.log("Error already displayed");
      return;
    }

    if (!title)
      title = 'Alert';

    // if (!message)
    //   message = 'Something happened...';

    this.dialogService.open({ viewModel: GenericDlg, model: { title: title, message: message, isPlainText: isPlainText } })
      .then((dlg: DialogOpenResult) => {
        console.log('alert Dialog Open');
        this.alertDlg = dlg;
        return dlg.closeResult;
      }).then((response) => {
        console.log('alert Dialog Close');
        this.alertDlg = null;
      });
  }

  handleException(error: Error) {
    if (!error) {
      return;
    }

    let msg = error.message;
    console.log("handleException ERROR HANDLED", msg);

    if (msg && _.includes(msg, "NotAuthorized")) {
      msg = "The device username and/or password configured are not correct";
    }

    this.alert("Error detectado", msg);
  }

  removeOneConfirming<T>(clazz: { new(): T }, path: string, id: number): Promise<any> {

    return this.confirm('Removing item').then(b => {
      if (!b)
        return Promise.reject(b);

      return this.removeOne(clazz, path, id);
    });
  }


  confirm(title?: string, message?: string): Promise<{}> {

    if (!title)
      title = 'Confirmation';

    if (!message)
      message = 'Are you sure you want to continue?';

    return new Promise((resolve, reject) => {
      this.dialogService.open({ viewModel: GenericDlg, model: { title: title, message: message } })
        .whenClosed(response => {
          resolve(!response.wasCancelled)
        });
    });
  }


  // The most basic get, the respone is parsed from json
  public getPath(url: string, params: {} = undefined): Promise<any> {
    // console.log("GET PATH");
    if (params)
      url = url + "?" + HttpService.buildQueryString(params);

    console.log("GET PATH", url);
    return this.httpClient.get(url)
      .then(resp => {
        if (this.checkErrors(resp))
          return resp;

        return resp.data;
      })
      // .then(json => {
      //   return json.data;
      // })
      .catch(e => {
        // console.log("GET PATH ERROR", e);
        this.handleException(e);
      });
  };

  public getBlob(url: string, params: {} = undefined): Promise<any> {
    if (params)
      url = url + "?" + HttpService.buildQueryString(params);

    return new Promise<any>((resolve, reject) => {
      this.httpClient.blob(url)
        .then(resp => this.checkErrors(resp))
        // .then(response => response.blob())
        // .then(imageBlob => { return resolve(URL.createObjectURL(imageBlob)) })
        .then(imageBlob => { return resolve(imageBlob) })
        .catch(error => {
          this.handleException(error);
          // return reject(error);
        });
    });
  }
  // public getBlob(url: string, params: {} = undefined): Promise<any> {
  //     return new Promise<any>((resolve, reject) => {
  //         this.http.fetch(this.buildQuery(url, params))
  //             .then(response => response.blob())
  //             .then(imageBlob => { return resolve(URL.createObjectURL(imageBlob)) })
  //             .catch(error => {
  //                 this.handleException(error);
  //                 return reject(error);
  //             });
  //     });
  // }

  //-----//
  public getList<T>(clazz: { new(): T }, url: string, params: {} = undefined): Promise<any> {
    if (params)
      url = url + "?" + HttpService.buildQueryString(params);

    // console.log("GET LIST", url);
    return new Promise<T[]>((resolve, reject) => {
      this.httpClient.get(url)
        .then(resp => this.checkErrors(resp))
        .then(json => {

          let data = json;
          let links = undefined;
          /*
          TODO: Support pagination

          let data = json.data;
          console.log("DATA", json, data);
          
          if (data) {
            // Support paginated responses from the server
            let items = data.items;
            links = data._links;

            if (items) {
              data = items;
            }
          }
          */

          let result;

          if (data && data instanceof Array) {
            let itemList: T[] = [];
            data.forEach(jsonObject => {
              let item = MapUtils.deserialize(clazz, jsonObject);
              item["json"] = jsonObject;
              ImperativeJsonProperty(item, 'json', '-');
              itemList.push(item);
            });


            if (links) {
              result = { items: itemList, links: links };
            } else {
              result = itemList;
            }

            // By default publish an event with the list of items received by this API path
            this.eventAggregator.publish('api:list:' + url, result);
          }

          return resolve(result);
        })
        .catch(e => {
          // console.log("GET LIST ERROR", e);
          this.handleException(e);
          // return reject(e);
        })
    });
  };


  //-----//

  public getOne<T>(clazz: { new(): T }, url: string, id: number = undefined, params: {} = undefined): Promise<T> {
    if (id)
      url += "/" + id;

    if (params)
      url = url + "?" + HttpService.buildQueryString(params);

    //console.log("getOne", url);

    return new Promise<T>((resolve, reject) => {
      this.httpClient.get(url)
        .then(resp => this.checkErrors(resp))
        .then(json => {

          console.log("GetOne:", json);

          let data = json;
          if (json instanceof Array && json.length > 0) {
            data = json[0];
          }

          // let data = json.data;
          let item: T = undefined;
          if (data) {

            //console.log("getOne Response", data);

            item = MapUtils.deserialize(clazz, data);
            //if (clazz.name == Device.name)
            item["json"] = data;


            // console.log("GET ONE DATA ITEM", item);

            this.eventAggregator.publish('api:one:' + url, item);
          }

          return resolve(item);
        })
        .catch(e => {
          console.log("getOne:", e);

          this.handleException(e);
          // return reject(e);
        })
    });
  }

  // ---- // 
  // Update one
  updateOne<T>(clazz: { new(): T }, item: T, path: string, id: any = 0): Promise<any> {
    let url = path;
    if (id && (typeof (id) == "number" && id > 0 || typeof (id) == "string"))
      url += "/" + id;

    let obj = MapUtils.serialize(clazz, item);
    // delete obj['json'];


    console.log("updateOne", obj);
    //console.log("updateOne", JSON.stringify(obj));
    return new Promise<T>((resolve, reject) => {

      this.httpClient.put(url, obj)
        .then(resp => this.checkErrors(resp))
        .then(resp => {
          return resolve(resp);
        })
        .catch(e => {
          this.handleException(e);
          // return reject(e);
        })
    });
  }

  serializeObject<T>(clazz: { new(): T }, item: T): any {
    let obj = MapUtils.serialize(clazz, item);
    // delete obj['json'];
    return obj;
  }

  put(url, params: {} = undefined, data: {} = undefined): Promise<any> {

    if (params)
      url = url + "?" + HttpService.buildQueryString(params);

    console.log("PUT:", url, params, data);

    return new Promise<any>((resolve, reject) => {
      this.httpClient.put(url, data)
      .then(resp => this.checkErrors(resp))
      .then(json => {
        return resolve(json.data);
      })
      .catch(e => {
        this.handleException(e);
      })
    });
  }

  post(url, params: {} = undefined, data: {} = undefined): Promise<any> {

    if (params)
      url = url + "?" + HttpService.buildQueryString(params);

    console.log("post", JSON.stringify(data));
    return  new Promise<any>((resolve, reject) => {
      this.httpClient.post(url, data)
      .then(resp => this.checkErrors(resp))
      .then(json => {
        return resolve(json.data);
      })
      .catch(e => {
        this.handleException(e);
      })
    });
  }

  // ---- // 
  // Create one
  createOne<T>(clazz: { new(): T }, item: T, path: string): Promise<any> {
    let url = path;
    let obj = MapUtils.serialize(clazz, item);

    //console.log("createOne", JSON.stringify(obj));
    return new Promise<T>((resolve, reject) => {

      this.httpClient.post(url, obj)
        .then(resp => this.checkErrors(resp))
        .then(resp => {
          return resolve(resp);
        })
        .catch(e => {
          this.handleException(e);
          return reject(e);
        })
    });
  }

  // ---- //
  // Generic function to save an item
  saveOne<T>(clazz: { new(): T }, item: T, path: string, id: any = 0): Promise<any> {
    if (id == 0) { // Create or Update without ID ( for example for ports )
      return this.createOne(clazz, item, path);
    }

    // Update by ID
    return this.updateOne(clazz, item, path, id);
  }

  //-----//
  // Generic function to remove items. 
  removeOne<T>(clazz: { new(): T }, path: string, id: any): Promise<any> {

    let url = path;
    if (id > 0) {
      url += "/" + id;
    }
    return new Promise<T>((resolve, reject) => {
      this.httpClient.delete(url)
        .then(resp => this.checkErrors(resp))
        .then(json => {
          console.log("removeOne", json);
          return resolve(json);
        })
        .catch(e => {
          this.handleException(e);
          // return reject(e);
        })
    });
  }


  uploadFile<T>(path: string, id: number, formData: FormData): Promise<any> {

    let url = path;
    if (id > 0)
      url += "/" + id;

    this.uploading = true;
    return new Promise<any>((resolve, reject) => {
      this.httpClient.upload(url, formData)
        .then(response => {
          this.uploading = false;
          console.log("FILE UPLOADED", response.data)
          return resolve(response.data);
        })
        .catch(e => {
          console.log("UPLOAD ERROR", e);
          this.handleException(e)
          // return reject(e)
        });
    });
  }

  //------//
  saveTextAs(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  //------//

  // Function to download data to a file
  saveBlobAs(filename, blob) {
    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, filename);
    }
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  //------//

  downloadFile<T>(path: string, params: {} = undefined): Promise<any> {
    if (params)
      path = path + "?" + HttpService.buildQueryString(params);

    let filename = "backup";
    return new Promise<any>((resolve, reject) => {
      this.httpClient.blob(path)
        .then(resp => {
          let response = resp.response;
          console.log("downloadFile", response);

          let content = response.headers.get('Content-Disposition')
          if (content) {
            filename = content.replace("attachment; filename=", "");
          }
          return this.checkErrors(resp);
        })
        .then(blob => {
          this.saveBlobAs(filename, blob);
        })
        .catch(error => {
          this.handleException(error);
        });
    });
  }

  // // Load datatable plugin for the table id specified
  // loadDataTable(id: string, options?: any): Promise<any> {
  //   console.log("loadDataTable");
  //   return new Promise<any>((resolve, reject) => {
  //     /*setTimeout(() => {

  //       let tableConfig = {
  //         destroy: true,
  //         preDrawCallback: function (settings) {
  //           var api = new $.fn.dataTable.Api(settings);
  //           var pagination = $(this)
  //             .closest('.dataTables_wrapper')
  //             .find('.dataTables_paginate');
  //           pagination.toggle(api.page.info().pages > 1);
  //         },
  //         "pageLength": 50,
  //         "language": {
  //           "sProcessing": "Procesando...",
  //           "sLengthMenu": "Mostrar _MENU_ registros",
  //           "sZeroRecords": "No se encontraron resultados",
  //           "sEmptyTable": "Ningún dato disponible en esta tabla",
  //           "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
  //           "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
  //           "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
  //           "sInfoPostFix": "",
  //           "sSearch": "Buscar:",
  //           "sUrl": "",
  //           "sInfoThousands": ",",
  //           "sLoadingRecords": "Cargando...",
  //           "oPaginate": {
  //             "sFirst": "Primero",
  //             "sLast": "Último",
  //             "sNext": "Siguiente",
  //             "sPrevious": "Anterior"
  //           },
  //           "oAria": {
  //             "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
  //             "sSortDescending": ": Activar para ordenar la columna de manera descendente"
  //           }
  //         }
  //       }
  //       if (options && options.order) {
  //         tableConfig["order"] = options["order"];
  //       }
  //       let table = $('#' + id).DataTable(tableConfig);
  //       return resolve(table);
  //     }, 500);
  //     */
  //   });
  // }


  ///////////////////////
  getEmpresas(): Promise<any> {
    return this.getList(Empresa, "empresas").then(list => {
      this.empresas = [];
      if (list) {
        this.empresas = list;
      }
    });
  }

  empresaByID(id: number): Empresa {
    return this.empresas.find((e) => e.id == id);
  }


  //////////////////

  getEmpresasCdt(empresaID: number): Promise<any> {
    let params = {
      empresa: empresaID
    }
    let emp = this.empresaByID(empresaID);
    return this.getList(EmpresaCdT, 'empresa_centros', params)
      .then(list => {
        if (list) {
          this.empresasCdt = list;
          this.empresasCdt.forEach(e => {
            e.empresaCached = emp;
          });
        }
      });
  }

  empresaCdtByID(id: number): EmpresaCdT {
    return this.empresasCdt.find((e) => e.id == id);
  }

  //////////////////

  getTrabajadores(emp: Empresa): Promise<any> {
    let params = {
      empresa: emp.id,
    }
    return this.getList(Trabajador, 'trabajadores', params)
      .then(items => {
        if (items) {
          items.forEach(t => {
            t.empresaCached = emp;
          });
          emp.trabajadores = items;
        }
      });
  }

  //////////////////

  getMaquinas(emp: Empresa): Promise<any> {
    let params = {
      empresa: emp.id,
    }
    return this.getList(Maquina, 'maquinas_empresas', params)
      .then(items => {
        if (items) {
          items.forEach(m => {
            m.empresaCached = emp;
          });
          emp.maquinas = items;
        }
      });
  }


  //////////////////

  getTrabajadoresCdt(empCdt: EmpresaCdT, incluirSubCentros: boolean = false): Promise<any> {
    let params = {
      empresa: empCdt.empresa_id,
      empresa_cdt: empCdt.id,
      incluir_subcdt: incluirSubCentros
    }

    return this.getList(TrabajadorCdt, 'empresa_centro_trabajadores', params)
      .then(items => {
        if (items) {

          // Ñapa porque no se serializa bien el campo Trabajador dentro de TrabajadorCdt
          for (let trabCdt of items) {
            trabCdt.trabajador["nombreCompleto"] = trabCdt.nombreCompleto;
            trabCdt.trabajador["nombreCompletoCif"] = trabCdt.nombreCompletoCif;
          }
          empCdt.trabajadoresCdt = items;

          // console.log("getTrabajadoresCdt", items);
        }
      });
  }

  //////////////////

  getTrabajadoresActPrev(empCdt: EmpresaCdT, actPrev: ActividadPreventiva): Promise<any> {
    let params = {
      empresa: empCdt.empresa_id,
      empresa_cdt: empCdt.id,
      actividad: actPrev.id
    }

    return this.getList(TrabajadorActividadPreventiva, 'actividades_preventivas_trabajadores', params)
      .then(items => {
        if (items) {
          actPrev.trabajadores = items;
        }
      });
  }


  //////////////////

  getTrabajadoresAccidente(empCdt: EmpresaCdT, acc: Accidente): Promise<any> {
    let params = {
      empresa: empCdt.empresa_id,
      empresa_cdt: empCdt.id,
      accidente: acc.id
    }

    return this.getList(TrabajadorAccidente, 'accidentes_trabajadores', params)
      .then(items => {
        if (items) {
          acc.trabajadores = items;
        }
      });
  }

  //////////////////

  getTrabajadoresDelta(tipo: string, empID: number, fecha: string, ccc: string): Promise<any> {
    let params = {
      tipo: tipo,
      empresa: empID,
      fecha: fecha,
      ccc: ccc,
    }

    return this.getList(TrabajadorAccidente, 'accidentes_trabajadores_ccc', params);
  }

  //////////////////

  downloadPAT(tipo: string, empID: number, fecha: string, ccc: string, accTrabID: number = 0): Promise<any> {
    let params = {
      tipo: tipo,
      empresa: empID,
      fecha: fecha,
      ccc: ccc,
      xml: true,
      acc_trab_id: accTrabID,
    }

    return this.downloadFile('accidentes_trabajadores_ccc', params);
  }

  //////////////////

  downloadRATSB(tipo: string, empID: number, fecha: string, ccc: string): Promise<any> {
    let params = {
      tipo: tipo,
      empresa: empID,
      fecha: fecha,
      ccc: ccc,
      xml: true
    }

    return this.downloadFile('accidentes_trabajadores_ccc', params);
  }

  //////////////////

  getEmpresasCCC(empID: number): Promise<any> {
    let params = {
      empresa: empID,
    }

    return this.getList(EmpresaCCC, 'empresa_ccc', params);
  }

  //////////////////

  showSnackbar(title, message: string, type?: string) {

    if (!type) {
      type = "success";
    }


    // Toastr.options = {
    //   // toastClass: 'alert',
    //   // iconClasses: {
    //   //     error: 'alert-error',
    //   //     info: 'alert-info',
    //   //     success: 'alert-success',
    //   //     warning: 'alert-warning'
    //   // },
    //   "closeButton": true,
    //   "debug": false,
    //   "newestOnTop": true,
    //   // "progressBar": true,
    //   "positionClass": "toast-bottom-center",
    //   // "positionClass": "toast-top-right",
    //   // "preventDuplicates": false,
    //   "onclick": null,
    //   "showDuration": "300",
    //   "hideDuration": "1000",
    //   "timeOut": "3000",
    //   "extendedTimeOut": "1000",
    //   "showEasing": "swing",
    //   "hideEasing": "linear",
    //   "showMethod": "fadeIn",
    //   "hideMethod": "fadeOut"
    // };

    // if (type == "success") {
    //   Toastr["success"](message, title);
    // } else {
    //   Toastr["error"](message, title);
    // }
  }

  // handleDialogResponse(response) {
  //   if (!response.wasCancelled) {
  //     this.showSnackbar('Información enviada correctamente', "");
  //   }
  // }

  showEditorDialog(params): Promise<any> {
    return this.dialogService.open(params)
      .whenClosed(response => {
        // console.log("showEditorDialog", response);

        // this.handleDialogResponse(response);
        return response;
      });
  }

  cancelEvent(event) {
    if (!event)
      return;
    event.preventDefault();
    event.stopPropagation();
  }

  blockUI(message) {
    // console.log("BLOCK UI");

    let html = '<div class="align-items-center text-wrap">' +
      '<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>' +
      '<h3 class="m-2">' + message + '</h3></div>';

    $.blockUI({
      message: html,
      overlayCSS: {
        backgroundColor: '#eb5600',
        opacity: 0.9,
        cursor: 'wait'
      },
      css: {
        // width: 'none',
        // left: 'none', 
        border: 0,
        color: '#fff',
        // color: '#000',
        padding: 0,
        backgroundColor: 'transparent'
        // backgroundColor: '#fff',
      },
      baseZ: 2000,
    });;
  }

  unblockUI() {
    console.log("UNBLOCK UI");
    $.unblockUI()
  }

  public updateList<T>(clazz: { new(): T }, url: string, list: T[], id: any = 0): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      if (list && list.length > 0) {
        resolve(list);
      } else {
        this.getList(clazz, url, {}).then((items) => {
          let ninguno = new clazz();
          ninguno['id'] = id;
          ninguno['nombre'] = this.tr("ninguno");
          list = [ninguno];
          list.push(...items);
          resolve(list);
        });
      }
    });
  }

  updateFormasDeContacto() {
    this.updateList(FormaDeContacto, "formas_contacto", this.formaDeContactoList, "")
      .then(list => this.formaDeContactoList = list);
  }

  updateAgentesMateriales() {
    this.updateList(AgenteMaterial, "agentes_materiales", this.agentesMateriales, "")
      .then(list => this.agentesMateriales = list);
  }

  updateParteDelCuerpo() {
    this.updateList(ParteDelCuerpo, "partes_cuerpo", this.partesDelCuerpo, "")
      .then(list => this.partesDelCuerpo = list);
  }

  updateLesiones() {
    this.updateList(Lesion, "lesiones", this.lesiones, "")
      .then(list => this.lesiones = list);
  }

  updateNaturalezaDeLaLesion() {
    this.updateList(NaturalezaDeLaLesion, "naturalezas_lesion", this.naturalezaDeLaLesion, "")
      .then(list => this.naturalezaDeLaLesion = list);
  }

  updateHorasDelTrabajo() {
    this.updateList(HoraDelTrabajo, "horas_trabajo", this.horasDelTrabajoList, "")
      .then(list => this.horasDelTrabajoList = list);
  }

  updateActividadesCt() {
    this.updateList(ActividadCt, "actividades_ct", this.actividadesCt)
      .then(list => this.actividadesCt = list);
  }

  updateLugaresCt() {
    this.updateList(LugarCt, "lugares_ct", this.lugaresCt)
      .then(list => this.lugaresCt = list);
  }

  updateMensajesCt() {
    this.updateList(MensajeCt, "mensajes_ct", this.mensajesCt)
      .then(list => this.mensajesCt = list);
  }

  updateTiposComision() {
    this.updateList(TipoComision, "tipos_comision", this.tiposComision)
      .then(list => this.tiposComision = list);
  }

  updateTiposLibroAct() {
    this.updateList(TipoLibroAct, "tipos_libro_act", this.tiposLibroAct)
      .then(list => this.tiposLibroAct = list);
  }

  updateTiposRegistroLibro() {
    this.updateList(TipoRegistroLibro, "tipos_registro_libro", this.tiposRegistroLibro)
      .then(list => this.tiposRegistroLibro = list);
  }

  updateTiposInforme() {
    this.updateList(TipoInforme, "tipos_informe", this.tiposInforme)
      .then(list => this.tiposInforme = list);
  }

  updateTipoEstadoInspeccion() {
    this.updateList(TipoEstadoInspeccion, "tipos_estado_inspeccion", this.tiposEstadoInspeccion)
      .then(list => this.tiposEstadoInspeccion = list);
  }

  updateSubTiposActividadesPreventivas() {
    this.updateList(SubtipoActividadPreventiva, "subtipos_actividades_preventivas", this.subTiposActPrev, "")
      .then(list => this.subTiposActPrev = list);
  }

  updateRiesgos() {
    this.updateList(Riesgo, "riesgos", this.riesgos, "")
      .then(list => this.riesgos = list);
  }

  updateFactoresRiesgos() {
    this.updateList(FactorRiesgo, "factores_riesgo", this.factoresRiesgo, "")
      .then(list => this.factoresRiesgo = list);
  }

  updateConsecuencias() {
    this.updateList(Consecuencia, "consecuencias", this.consecuencias)
      .then(list => this.consecuencias = list);
  }

  updateNivelesExposicion() {
    this.updateList(NivelExposicion, "niveles_exposicion", this.nivelesExposicion)
      .then(list => this.nivelesExposicion = list);
  }

  updateProbabilidadSuceso() {
    this.updateList(ProbabilidadSuceso, "probabilidad_suceso", this.probabilidadSuceso)
      .then(list => this.probabilidadSuceso = list);
  }

  updateCategoriasPEE() {
    this.updateList(CategoriaPEE, "categorias_pee", this.categoriasPEE)
      .then(list => this.categoriasPEE = list);
  }

  updateCargosPEE() {
    this.updateList(CargoPEE, "cargos_pee", this.cargosPEE)
      .then(list => this.cargosPEE = list);
  }

  updateTurnosPEE() {
    this.updateList(TurnoPEE, "turnos_pee", this.turnosPEE)
      .then(list => this.turnosPEE = list);
  }

  updateAptosMedicos() {
    this.updateList(AptoMedicoLaboral, "apto_medico_laboral", this.aptosMedicosList)
      .then(list => this.aptosMedicosList = list);
  }

  updateAtributosFormacion() {
    this.updateList(AtributoFormacion, "atributos_formacion", this.atributosFormacionList)
      .then(list => this.atributosFormacionList = list);
  }

  updateAtributosCalidad() {
    this.updateList(AtributoCalidad, "atributos_calidad", this.atributosCalidadList)
      .then(list => this.atributosCalidadList = list);
  }

  getActividadPreventivaCompleta(params): Promise<any> {
    let actPrevID = params.actPrevID;
    let empID = params.empID;
    let empCdT = params.empCdt;

    return new Promise<any>((resolve, reject) => {

      let empresa: Empresa;

      this.getOne(Empresa, "empresas", empID).then(emp => {
        empresa = emp;
        this.getOne(EmpresaCdT, 'empresa_centros', empCdT, { empresa: empID })
          .then((ret) => {
            let empresaCdt: EmpresaCdT = ret;
            empresaCdt.empresaCached = empresa;
            this.getOne(ActividadPreventiva, 'actividades_preventivas', actPrevID, { empresa: empID, empresa_cdt: empCdT })
              .then(item => {
                let actPrev: ActividadPreventiva = item;
                actPrev.empCT = empresaCdt;

                this.getMedidasCorrectoras(actPrev, false).then(() => {
                  this.getTrabajadoresActPrev(empresaCdt, actPrev).then(() => {
                    resolve(actPrev);
                  });
                })
              });
          });
      });
    });
  }


  getMedidasCorrectoras(actPrev: ActividadPreventiva, pendientes: boolean): Promise<any> {
    if (!actPrev) {
      return;
    }

    let params = {
      empresa: actPrev.empCT.empresa_id,
      empresa_cdt: actPrev.empCT.id,
      pendientes: pendientes,
      actividad: actPrev.id,
      tipo: 1,
    }
    return new Promise<any>((resolve, reject) => {
      this.getList(MedidaCorrectora, 'medidas_correctoras', params)
        .then((items) => {
          actPrev.situacionesRiesgo = items;
          resolve(items);
        });
    });
  }

  getFechaFormatted(fecha: moment.Moment): string {
    if (fecha && fecha.isValid()) {
      return fecha.format('DD-MM-YYYY');
    }
    return "";
  }

  getTituloInformeActPrev(actPrev: ActividadPreventiva, nombre: string): string {
    let fecha = moment(actPrev.fecha_inicio, "DD-MM-YYYY");
    let title = fecha.format("YYYY.MM.DD");

    if (actPrev.empCT) {
      if (actPrev.empCT.ct.codigo_unico) {
        title += " - " + actPrev.empCT.ct.codigo_unico;
      }

      if (actPrev.empCT.ct.id) {
        title += " - " + actPrev.empCT.ct.id
      }
    }

    title += " - " + nombre;
    return title;
  }

  public tr(text: string) {
    return this.i18n.tr(text);
  }

  public getLocale(): string {
    return this.i18n.getLocale();
  }

  public getStartTime(t: moment.Moment): moment.Moment {
    let ret = t.clone()
    ret.subtract(12, 'months');
    return ret;
  }

  sortArrayNatural(array: any[], key: string) {
    if (!array) {
      return [];
    }
    var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return array.sort((a, b) => {
      if (key != "") {
        return collator.compare(a[key], b[key]);
      } else {
        return collator.compare(a, b);
      }
    });
  }
  
}
