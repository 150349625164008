import { autoinject, transient, singleton } from "aurelia-framework";
import { getLogger } from "aurelia-logging";
import { HttpClient, json } from "aurelia-fetch-client";
import { EventAggregator } from "aurelia-event-aggregator";
import { Application } from 'utils/app-settings';

import AuthService from 'authService';

class RequestStatus {
  URL: string;
  requestTimer;
  isSlowRequest: boolean = false;
};

const loadingTimeout = 3 * 1000;

const sessionKey = "Pgs-Session"
@singleton()
@autoinject
export class HttpService {
  private logger;
  private logRequest: boolean = true;
  private authService: AuthService;


  private requestMap: Map<string, RequestStatus> = new Map<string, RequestStatus>();

  constructor(public httpClient: HttpClient, public app: Application, public ea: EventAggregator) {

    this.logger = getLogger('HttpService');
    this.logger.info('Initialized');

    let self = this;
    httpClient.configure(
      config => {
        config
          .withBaseUrl(app.settings.Http.BaseUrl)
          //.withDefaults({})
          .withInterceptor({
            request(request) {
              if (self.logRequest)
                self.logger.info(`Requesting ${request.method} ${request.url}`);


              // What happens if we ask for the same url twice?
              let rs = self.requestMap.get(request.url);
              if (rs) {
                clearTimeout(rs.requestTimer)
              } else {
                rs = new RequestStatus();
                rs.URL = request.url;
                self.requestMap.set(rs.URL, rs);
              }
              rs.requestTimer = setTimeout(() => {
                console.log("!!!! Request started, taking longer than", loadingTimeout, rs.URL);
                rs.isSlowRequest = true;
                self.ea.publish('http:slow_request', rs);
              }, loadingTimeout);
              return request;
            },
            response(response) {
              if (self.logRequest)
                self.logger.info(`Response ${response} ${response.status} ${response.url}`);

              let rs = self.requestMap.get(response.url);
              clearTimeout(rs.requestTimer)
              if (rs.isSlowRequest) {
                console.log("!!!! Slow request ended", rs.URL);
                self.ea.publish('http:slow_request_ended', rs);
              }

              if (response instanceof TypeError) {
                throw Error("UIHttpServiceV2: " + response['message']);
              }

              if (response.status == 401 /*&& ~response.url.indexOf(self.httpClient.baseUrl)*/) {

                self.logger.info(`Response UNAUTH`, self.authService);
                // ea.publish('http::unauthorized', null);
                if (self.authService && self.authService.isAuthenticated()) {
                  self.authService.logout();
                }
              }
              else if (response.status >= 400) {
                return response.text()
                  .then(resp => {
                    let json: any = {};
                    let error = 'Network Error!!';
                    try {
                      json = JSON.parse(resp);
                    } catch (e) { }


                    self.logger.info(`Response error`, json);

                    if (json.message) error = json.message;
                    else if (json.error) error = json.error;
                    else if (response.statusText) error = response.statusText;


                    // if (error) throw {
                    //   code: response.status,
                    //   message: error
                    // }

                    if (error) {
                      var err = new Error(error);
                      err['code'] = response.status;
                      throw err;
                    }
                    //   throw new Error(error){
                    //   code: response.status,
                    //   message: error
                    // }
                    return null;
                  });
              }
              return response;
            },
            requestError(error) {
              if (this.logRequest)
                self.logger.error("requestError", error);
              if (error !== null)

                if (error) {
                  var err = new Error(error);
                  err['code'] = 500;
                  throw err;
                }

              // throw {
              //   code: 500,
              //   message: error
              // }
              return error;
            },
            responseError(error) {
              if (this.logRequest)
                self.logger.error("responseError", error);
              if (error !== null)
                var err = new Error(error);
              err['code'] = 500;
              throw err;

              // throw {
              //   code: 500,
              //   message: error
              // }
              return error;
            }
          });
      });
  }

  setAuthService(authService: AuthService) {
    this.authService = authService;
  }

  setBaseUrl(url) {
    this.httpClient.baseUrl = url;
  }

  static buildQueryString(json) {
    return Object.keys(json)
      .map(k => encodeURI(k) + "=" + encodeURI(json[k]))
      .join('&');
  }

  //**** SHARED METHODS ****//
  get(slug: string, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`get [${slug}]`);
    return this.httpClient
      .fetch(slug,
        {
          method: 'get',
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));
  }

  text(slug: string, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`text [${slug}]`);
    return this.httpClient
      .fetch(slug,
        {
          method: 'get',
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => {
        return { response: resp, data: resp.text() }
      });
  }

  blob(slug: string, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`text [${slug}]`);
    return this.httpClient
      .fetch(slug,
        {
          method: 'get',
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => {

        console.log("BLOB", resp);

        return { response: resp, data: resp.blob() }
      });
  }

  put(slug: string, obj, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`put [${slug}]`);
    // this.logger.info(json(obj));
    return this.httpClient
      .fetch(slug,
        {
          method: 'put',
          body: json(obj),
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));
  }

  post(slug: string, obj, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`post [${slug}]`);
    return this.httpClient
      .fetch(slug,
        {
          method: 'post',
          body: json(obj),
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));
  }

  delete(slug: string, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`delete [${slug}]`);
    return this.httpClient
      .fetch(slug,
        {
          method: 'delete',
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));
  }

  // upload(slug: string, form: HTMLFormElement, headers: any = true): Promise<any | string | void> {
  //     if (this.logRequest)
  //         this.logger.info(`upload [${slug}]`);
  //     return this.__upload('post', slug, form, headers);
  // }
  upload(slug: string, formData: FormData, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`upload [${slug}]`);
    // return this.__upload('post', slug, form, headers);

    return this.httpClient
      .fetch(slug,
        {
          method: 'post',
          body: formData,
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));
  }

  reupload(slug: string, formData: FormData, headers: any = true): Promise<any | string | void> {
    if (this.logRequest)
      this.logger.info(`reupload [${slug}]`);

    return this.httpClient
      .fetch(slug,
        {
          method: 'put',
          body: formData,
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));

    // return this.__upload('put', slug, form, headers);
  }

  private __upload(method: string, slug: string, form: HTMLFormElement, headers?) {
    var data = new FormData();
    for (var i = 0, q = (form.querySelectorAll('input') as NodeListOf<HTMLInputElement>); i < q.length; i++) {
      if (q[i].type == 'file') {
        let files = q[i]['draggedFiles'] || q[i].files;
        for (var x = 0; x < files.length; x++) {
          data.append(q[i].name || ('file' + (i + 1) + (x + 1)), (files[x].file || files[x]), files[x].name);
        }
      }
      else {
        data.append(q[i].name || ('input' + (i + 1)), q[i].value);
      }
    }
    return this.httpClient
      .fetch(slug,
        {
          method: method,
          body: data,
          mode: 'cors',
          headers: this.__getHeaders(headers)
        })
      .then(resp => this.__getResponse(resp));
  }

  private __getResponse(response) {
    if (response.status === 204) return null;
    return response.text().then(function (text) {
      try {
        return { response: response, data: JSON.parse(text) };
      }
      catch (e) {
        return {};
      }
    });
  }

  private __getHeaders(override = true) {
    var headers = {
      'X-Requested-With': 'Fetch',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    Object.assign(headers, this.app.settings.Http.Headers || {});

    let session = this.authService.getSession();
    if (override !== false && this.app.settings.Http.AuthorizationHeader && session) {
      headers[sessionKey] = session;
    }
    if (typeof override == 'object') {
      Object.assign(headers, override || {});
    }

    //console.log("Sending headers", headers);
    return headers;
  }


}
