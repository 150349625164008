export class FiltertipoValueConverter {
  toView(array, propertyName, filterType) {

    if (!array) {
      return [];
    }

    if (filterType == "") {
      return array;
    }

    return array.filter(v => {
      let tipo = v[propertyName];
      return tipo == filterType;
    });
  }
}


