import { Aurelia, singleton, autoinject } from "aurelia-framework";
import { Redirect, Router } from "aurelia-router";
import { EventAggregator } from "aurelia-event-aggregator";
import { getLogger } from "aurelia-logging";
import environment from 'environment';

@singleton()
export class AppSettings {
  AppKey: string;
  Title: string;
  SubTitle: string;
  AppWeb: string;
  BuildDate: string;
  UseOpenID: boolean;
  Version: string;
  Http: {
    BaseUrl: string;
    BaseWS: string;
    Headers: {};
    AuthorizationHeader: boolean;
  };
  Languages: ({
    id: string;
    name: string;
    rtl: boolean;
  } | {
    id: string;
    name: string;
  })[];

  showHeader: boolean = true;
  showFooter: boolean = true;

  constructor() {
  }
}


@singleton()
@autoinject()
export class Application {

  private logger;

  public isBusy: boolean = false;

  constructor(private aurelia: Aurelia, public settings: AppSettings, public router: Router, public eventAggregator: EventAggregator) {
    this.logger = getLogger('PGS_WEV');
    this.logger.info('Initialized');

    settings.Title = 'Preventium Global System';
    settings.Version = '1.0.0';
    settings.AppKey = 'pgs_web_5';
    settings.Http = {
      AuthorizationHeader: true,
      Headers: {
        'Aceptar': '*/*'
        // 'Content-Type': "application/json"
      },
      BaseUrl: '/api/v2/',
      BaseWS: '/api/v2/ws'
    }

    var getUrl = window.location;
    environment.basePath = getUrl.host;
    if (getUrl.protocol == "https:") {
      environment.https = true;
    }
    environment.debug = false;
    console.log("window.location", window.location, environment.basePath, environment.debug)


    //environment.https = false;
    if (environment.https) {
      // With HTTPS
      if (environment.debug) {
        //environment.basePath = "pgsweb.preinfa.com";
        environment.basePath = "preinfadesarrollo.pgsweb.preinfa.com";
        settings.Http.BaseUrl = 'https://' + environment.basePath + '/api/v2/';
      }
      settings.Http.BaseWS = 'wss://' + environment.basePath + "/api/v2/ws";
    } else {
      if (environment.debug) {
        // With HTTP
        //environment.basePath = "pgsweb.preinfa.com";
        // environment.basePath = "preinfadesarrollo.pgsweb.preinfa.com";
        environment.basePath = "localhost";
        settings.Http.BaseUrl = 'http://' + environment.basePath + '/api/v2/';
      }
      settings.Http.BaseWS = 'ws://' + environment.basePath + "/api/v2/ws";
    }

    // CAMBIAR!!!
    // environment.basePath = "pgsweb.preinfa.com";
    // settings.Http.BaseUrl = 'https://' + environment.basePath + '/api/v2/';
    // settings.Http.BaseWS = 'ws://' + environment.basePath + "/api/v2/ws";


    // CAMBIAR!!!
    // environment.basePath = "bonpreu.pgsweb.preinfa.com";
    // settings.Http.BaseUrl = 'https://' + environment.basePath + '/api/v2/';
    // settings.Http.BaseWS = 'ws://' + environment.basePath + "/api/v2/ws";


    environment.apiURL = settings.Http.BaseUrl;
    environment.wsURL = settings.Http.BaseWS;
  }

  navigate(hash, options?) {
    this.logger.info("navigate::" + hash);
    this.router.navigate(hash, options);
  }

  navigateTo(route, params = {}, options?) {
    this.logger.info("navigateTo::" + route);
    this.router.navigateToRoute(route, params, options);
  }

  routeActive(route) {
    return route.isActive || route.href == location.hash ||
      location.hash.indexOf(route.config.redirect || 'QWER') > -1;
  }

  /** Persistent State **/
  persist(key, value: any = '§') {
    if (window.localStorage) {
      if (value === '§') {
        return JSON.parse(window.localStorage.getItem(this.settings.AppKey + ':' + key));
      }
      else if (value === null) {
        window.localStorage.removeItem(this.settings.AppKey + ':' + key);
      }
      else {
        window.localStorage.setItem(this.settings.AppKey + ':' + key, JSON.stringify(value));
      }
    }
    return null;
  }

}
