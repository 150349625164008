import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class GenericDlg {
  title: string;
  message: string;
  isPlainText: boolean = false;

  constructor(private controller: DialogController) {

  }

  activate(model) {
    this.title = model.title;
    this.message = model.message;
    this.isPlainText = model.isPlainText;
  }
}
