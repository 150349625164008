export class SortValueConverter {
  toView(array, propertyName, direction) {
    if (!array) {
      return [];
    }

    let factor = (!direction || direction === 'ascending') ? 1 : -1;
    var collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
    return array.sort((a, b) => {
      // return (a[propertyName] - b[propertyName]) * factor;
      return collator.compare(a[propertyName], b[propertyName]) * factor;
    });
  }
}


