import { PLATFORM } from 'aurelia-pal';
import { FrameworkConfiguration } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./value-converters/number-format'),
    PLATFORM.moduleName('./value-converters/sort'),
    PLATFORM.moduleName('./value-converters/sortdate'),
    PLATFORM.moduleName('./value-converters/filterdate'),
    PLATFORM.moduleName('./value-converters/filtervalidado'),
    PLATFORM.moduleName('./value-converters/filterorigendoc'),
    PLATFORM.moduleName('./value-converters/filtertipo'),
    PLATFORM.moduleName('./value-converters/filterobligatoriedad'),
  ]);
}
