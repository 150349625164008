import moment from 'moment';

const serverFormat = 'DD-MM-YYYY HH:mm:ss';
const serverOnlyDateFormat = 'DD-MM-YYYY';

export class SortdateValueConverter {
  toView(array, propertyFecha, direction, propertyHora) {
    if (!array) {
      return [];
    }
    let factor = (!direction || direction === 'ascending') ? 1 : -1;
    return array.sort((a, b) => {

      let v1 = a[propertyFecha];
      let v2 = b[propertyFecha];
      let format = serverOnlyDateFormat;
      if (propertyHora) {
        format = serverFormat;
        v1 += " " + a[propertyHora];
        v2 += " " + b[propertyHora];
      }
      if (v1 instanceof Date && v2 instanceof Date) {

        // console.log("sort1", v1.getTime(), v2.getTime());
        return (v1.getTime() - v2.getTime()) * factor;
      } else {
        let aDt = 0;
        if (v1) {
          aDt = moment(v1, format).toDate().getTime();
        }

        let bDt = 0;
        if (v2) {
          bDt = moment(v2, format).toDate().getTime();
        }

        // console.log("sort2", aDt, bDt);
        return (aDt - bDt) * factor;
      }
    });
  }
}


