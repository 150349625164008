import { Obligatoriedad } from './../../entities/documento';

export class FilterobligatoriedadValueConverter {
  toView(array, propertyName, obligatoriedad) {

    if (!array) {
      return [];
    }

    if (obligatoriedad == Obligatoriedad.TODOS) {
      // console.log("FilterorigendocValueConverter TODOS");
      return array;
    }

    return array.filter(v => {

      let obligatorioDoc = v[propertyName];

      console.log("FilterobligatoriedadValueConverter TODOS", Number(obligatoriedad), obligatorioDoc);

      return (obligatoriedad == Obligatoriedad.OBLIGATORIO && obligatorioDoc) ||
        (obligatoriedad == Obligatoriedad.NO_OBLIGATORIO && !obligatorioDoc)

    });
  }
}


