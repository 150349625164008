import moment from 'moment';

import { OrigenDoc, SeleccionOrigenDocumento } from 'entities/documento';

export class FilterorigendocValueConverter {
  toView(array, propertyName, origenType) {

    if (!array) {
      return [];
    }

    console.log("FilterorigendocValueConverter", origenType)
    if (origenType == SeleccionOrigenDocumento.TODOS) {
      console.log("FilterorigendocValueConverter TODOS");
      return array;
    }

    return array.filter(v => {

      let origen = v[propertyName];

      console.log("FilterorigendocValueConverter Origen?", Number(origenType), origen);

      let type = Number(origenType);
      switch (type) {
        case SeleccionOrigenDocumento.EMPRESA:
          {
            return origen == OrigenDoc.EMPRESA || origen == OrigenDoc.EMP_CDT;
          }
        case SeleccionOrigenDocumento.TRABAJADOR:
          {
            console.log("TRABAJADOR", (origen == OrigenDoc.PERSONA), (origen == OrigenDoc.TRABAJADOR), (origen == OrigenDoc.TRAB_CDT));

            return origen == OrigenDoc.PERSONA || origen == OrigenDoc.TRABAJADOR || origen == OrigenDoc.TRAB_CDT || origen == OrigenDoc.TRAB_ACC;
          }
        case SeleccionOrigenDocumento.MAQUINA:
          {
            let esMaq = origen == OrigenDoc.MAQUINA || origen == OrigenDoc.MAQUINA_EMPRESA || origen == OrigenDoc.MAQ_CDT;
            if (esMaq) {
            console.log("Maquina!", esMaq);
            }
            return esMaq;
          }
        case SeleccionOrigenDocumento.CDT:
          {
            return origen == OrigenDoc.CDT;
          }
      }

      return false;
    });
  }
}


