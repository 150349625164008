import * as numeral from 'numeral'

export class NumberFormatValueConverter {

  // toView(value: string, format) {
  //     return numeral(value).format(format)
  // }

  fromView(value: string, format?: string) {
    let val = numeral(value).value();
    if (val == null) {
      val = 0
    }
    return val;
  }
}
