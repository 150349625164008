import 'reflect-metadata';

//
// All this comes from http://cloudmark.github.io/Json-Mapping/
//

export interface IJsonMetaData<T> {
  name?: string,
  clazz?: { new(): T }
}

//-----//

const jsonMetadataKey = "jsonProperty";

//-----//

export function JsonProperty<T>(metadata?: IJsonMetaData<T> | string): any {
  if (metadata instanceof String || typeof metadata === "string") {
    return Reflect.metadata(jsonMetadataKey, {
      name: metadata,
      clazz: undefined
    });
  } else {
    let metadataObj = <IJsonMetaData<T>>metadata;
    return Reflect.metadata(jsonMetadataKey, {
      name: metadataObj ? metadataObj.name : undefined,
      clazz: metadataObj ? metadataObj.clazz : undefined
    });
  }
}

//-----//

export function ImperativeJsonProperty<T>(target: Object, property: string | symbol, metadata?: IJsonMetaData<T> | string): any {
  if (metadata instanceof String || typeof metadata === "string") {
    return Reflect.defineMetadata(jsonMetadataKey, {
      name: metadata,
      clazz: undefined
    }, target, property);
  } else {
    let metadataObj = <IJsonMetaData<T>>metadata;
    return Reflect.defineMetadata(jsonMetadataKey, {
      name: metadataObj ? metadataObj.name : undefined,
      clazz: metadataObj ? metadataObj.clazz : undefined
    }, target, property);
  }
}

//-----//

export function getClazz(target: any, propertyKey: string): any {
  return Reflect.getMetadata("design:type", target, propertyKey)
}

//-----//

export function getJsonProperty<T>(target: any, propertyKey: string): IJsonMetaData<T> {
  return Reflect.getMetadata(jsonMetadataKey, target, propertyKey);
}

//////////////////////////////////////////////////

export default class MapUtils {
  static isPrimitive(obj) {
    switch (typeof obj) {
      case "string":
      case "number":
      case "boolean":
        return true;
    }
    return !!(obj instanceof String || obj === String ||
      obj instanceof Number || obj === Number ||
      obj instanceof Boolean || obj === Boolean);
  }

  //-----//

  static isArray(object) {
    if (object === Array) {
      return true;
    } else if (typeof Array.isArray === "function") {
      return Array.isArray(object);
    }
    else {
      return !!(object instanceof Array);
    }
  }

  //-----//

  static isDate(obj) {
    if (obj instanceof Date)
      return true;

    if (obj === Date) {
      return true;
    }
    return false;
  }

  static isMap(obj) {
    if (obj instanceof Map)
      return true;

    if (obj === Map) {
      return true;
    }
    return false;
  }


  //-----//
  static deserialize<T>(clazz: { new(): T }, jsonObject): T {
    if ((clazz === undefined) || (jsonObject === undefined)) {
      return undefined;
    }

    let obj = new clazz();
    Object.keys(obj).forEach((key) => {
      let propertyMetadataFn: (IJsonMetaData) => any = (propertyMetadata) => {
        let propertyName = propertyMetadata.name || key;
        let innerJson = jsonObject ? jsonObject[propertyName] : undefined;
        let clazz = getClazz(obj, key);

        if (MapUtils.isArray(clazz)) {
          let metadata = getJsonProperty(obj, key);
          if (metadata.clazz || MapUtils.isPrimitive(clazz)) {
            if (innerJson && MapUtils.isArray(innerJson)) {
              return innerJson.map(
                (item) => MapUtils.deserialize(metadata.clazz, item)
              );
            } else {
              // if (MapUtils.isArray(innerJson)) {
              //     return [];
              // }

              return [];
            }
          } else {
            return innerJson;
          }
        } else if (MapUtils.isDate(clazz)) {

          // Handle empty date from SQL DB
          if (innerJson == "0001-01-01T00:00:00Z")
            return new Date(0);

          return new Date(innerJson);
        } else if (MapUtils.isPrimitive(clazz)) {
          return innerJson;
        } else if (MapUtils.isMap(clazz)) {
          return innerJson;
        } else {
          return MapUtils.deserialize(clazz, innerJson);
        }
      };

      let propertyMetadata = getJsonProperty(obj, key);
      if (propertyMetadata) {
        if (propertyMetadata.name != "-") {
          obj[key] = propertyMetadataFn(propertyMetadata);
        }
      } else {
        if (jsonObject && jsonObject[key] !== undefined) {
          obj[key] = jsonObject[key];
        }
      }
    });
    return obj;
  }

  //-----//

  static serialize<T>(clazz: { new(): T }, obj): object {
    if ((clazz === undefined) || (obj === undefined)) {
      return undefined;
    }

    let jsonObject = {};
    Object.keys(obj).forEach((key) => {
      let propertyMetadataFn: (IJsonMetaData) => any = (propertyMetadata) => {
        let innerObj = obj ? obj[key] : undefined;
        let clazz = getClazz(obj, key);
        if (MapUtils.isArray(clazz)) {
          let metadata = getJsonProperty(obj, key);

          if (metadata.clazz || MapUtils.isPrimitive(clazz)) {
            if (innerObj && MapUtils.isArray(innerObj)) {
              return innerObj.map(
                (item) => MapUtils.serialize(metadata.clazz, item)
              );
            } else {
              return undefined;
            }
          } else {
            return innerObj;
          }
        } else if (!MapUtils.isPrimitive(clazz) && !MapUtils.isDate(clazz)) {
          return MapUtils.serialize(clazz, innerObj);
        } else {
          return obj ? obj[key] : undefined;
        }
      };

      let propertyMetadata = getJsonProperty(obj, key);
      if (propertyMetadata) {
        let propertyName = propertyMetadata.name || key;
        if (propertyMetadata.name != "-") {
          jsonObject[propertyName] = propertyMetadataFn(propertyMetadata);
        } else {
          //console.log("NO JSONIZO", key)
        }
      } else {
        if (obj && obj[key] !== undefined) {
          jsonObject[key] = obj[key];
        }
      }
    });

    // Remove unnecessary keys
    delete jsonObject['json'];
    return jsonObject;
  }
}
